import React from 'react'
import logoImg from '../elements-contact/images/logo@2x.png'
import SEO from '../components/seo'

import '../elements-contact/css/bootstrap.css'
import '../elements-contact/style.css'
import '../elements-contact/ventana.css'
import '../elements-contact/css/font-icons.css'
import '../elements-contact/css/fonts/stylesheet.css'
import '../elements-contact/contacto.css'

function ContactPage() {
  return (
    <div>
      <SEO title="Be Grand | Downtown Reforma" main />
      <header
        id="header"
        className="transparent-header "
        data-sticky-shrink="false"
        data-animate="fadeInDown"
      >
        <div id="header-wrap">
          <div className="container">
            <div className="header-row">
              <div id="logo">
                <a
                  href="/contacto"
                  data-dark-logo={logoImg}
                  className="standard-logo"
                >
                  <img src={logoImg} alt="Be Grand" />
                </a>
                <a
                  href="/contacto"
                  data-dark-logo={logoImg}
                  className="retina-logo"
                >
                  <img src={logoImg} alt="Be Grand" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="header-wrap-clone"></div>
      </header>
      <div id="wrapper" className="clearfix">
        <section
          id="slider"
          className="slider-element min-vh-60 min-vh-md-100 dark include-header include-topbar"
        >
          <div className="slider-inner">
            <div
              className="fslider h-100 position-absolute"
              data-speed="1000"
              data-pause="5000"
              data-animation="fade"
              data-arrows="false"
              data-pagi="false"
            >
              <div className="flexslider">
                <div className="slider-wrap">
                  <div
                    className="slide"
                    style={{
                      background: 'url("images/fondo3.jpg") center center',
                      backgroundSize: 'cover',
                    }}
                  ></div>
                </div>
              </div>
            </div>

            <div className="vertical-middle">
              <div className="container text-center">
                <div className="emphasis-title m-0">
                  <div className="vertical-middle slider-element-fade">
                    <div className="container ">
                      <div className="login-23">
                        <div className=" p-0 m-0">
                          <div className="col-md-12 pad-0">
                            <div className="row login-box-12">
                              <div
                                className="col-lg-8 offset-lg-2 col-sm-12 align-self-center aligncenter align-items-center logobg m-0 pb-3"
                                data-animate="fadeInDown"
                              >
                                <img src="images/logo.png" />
                              </div>
                              <div
                                className="col-lg-8 offset-lg-2 col-sm-12 align-self-center header-form p-5"
                                data-animate="fadeInRight"
                              >
                                <h2 className="titulo pb-5">
                                  GRACIAS POR<br></br>REGISTRARTE
                                </h2>
                                <h3 className="subtitulo">
                                  EN BREVE{' '}
                                  <span>NOS PONDREMOS EN CONTACTO CONTIGO</span>{' '}
                                  PARA BRINDARTE MÁS INFORMACIÓN DE{' '}
                                  <span>DOWNTOWN REFORMA</span>
                                </h3>
                              </div>

                              <div
                                className="col-lg-12 col-sm-12 align-self-center"
                                data-animate="fadeInLeft"
                              >
                                <div className="form-group my-5">
                                  <a
                                    className="btn btn-formulario-thankyou"
                                    href="/contacto"
                                  >
                                    REGRESAR AL INICIO
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}

export default ContactPage
